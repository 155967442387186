:focus {
  outline: 0 !important;
}
button {
  letter-spacing: 1.25px;
  // mat icons style
  &.btn-32 {
    @apply w-8 h-8 flex justify-center items-center min-w-0;
  }
  &.btn-36 {
    @apply w-9 h-9 flex justify-center items-center min-w-0;
  }
  &.btn-40 {
    @apply w-10 h-10 flex justify-center items-center min-w-0;
  }
  &.btn-48 {
    @apply w-12 h-12 flex justify-center items-center min-w-0;
  }
}

.h-available {
  height: -webkit-fill-available;
}
.w-available {
  width: -webkit-fill-available;
}
.line-ellipsis {
  @apply whitespace-nowrap overflow-hidden text-ellipsis;
}

.non-selectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

// Scroll --------------------------------------------------------------
.square-scroll::-webkit-scrollbar {
  @apply w-3 h-3;
  background-color: gainsboro;
}
.square-scroll::-webkit-scrollbar-button {
  @apply w-0 h-0 hidden;
}
.square-scroll::-webkit-scrollbar-corner {
  @apply bg-transparent;
}
.square-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow:
    inset 1px 1px 0px rgb(0 0 0 / 10%),
    inset 0px -1px 0px rgb(0 0 0 / 7%);
}
.square-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-box-shadow:
    inset 1px 1px 0px rgb(0 0 0 / 20%),
    inset 0px -1px 0px rgb(0 0 0 / 14%);
}
.square-scroll::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow:
    inset 1px 1px 0px rgb(0 0 0 / 15%),
    inset 0px -1px 1px rgb(0 0 0 / 15%);
}

.narrow-scroll::-webkit-scrollbar {
  @apply w-2 h-2;
  background: transparent;
}
.narrow-scroll::-webkit-scrollbar-thumb {
  @apply rounded-lg min-h-[40px];
  border: 4px solid gainsboro;
}
//////////////////////////////////////////////////////////////////////////////
