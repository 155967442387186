.mat-menu-panel {
  min-height: auto !important;
}

.menu-sm.mat-menu-panel {
  .mat-menu-content {
    padding: 6px 0;
  }

  button.mat-menu-item {
    @apply h-7 p-0 text-sm flex items-center px-3;
    .mat-icon {
      @apply w-[18px] h-[18px] text-[18px] mr-2;
    }
  }
}

.menu-md.mat-menu-panel {
  .mat-menu-content {
    padding: 6px 0;
  }

  button.mat-menu-item {
    @apply h-9 p-0 text-base flex items-center px-3;
    .mat-icon {
      @apply w-[18px] h-[18px] text-[18px] mr-2;
    }
  }
}
